export const getProductUSP = (brandsConfig, brandId, productId) => {
  if (!brandsConfig || Object.keys(brandsConfig).length <= 0) {
    return null;
  }

  const USPList = brandsConfig.usp;

  if ((Object.keys(USPList[brandId] ?? {}).length <= 0 || Object.keys(USPList[brandId][productId] ?? {}).length <= 0)) {
    return null
  }

  return USPList[brandId][productId];
}