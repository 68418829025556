import { areaCode } from 'fixtures/vehicle-area-code';
import get from 'lodash/get';
import qs from 'query-string';

export const getAgentCategory = (props) => {
  const { category, phone } = props;
  let processUrl = '/';

  switch (category) {
    case 'LIFE':
      const { gender, age, budget, salary } = props;
      processUrl = `/search?category=${category === 'LIFE' ? 'life' : 'health'}`;

      if (gender && age && phone) {
        const query = {
          gender: gender,
          age: age,
          phoneNumber: phone,
          bugget: budget || 0,
          salary: salary || 0,
          isSalary: !!salary,
          category: category === 'LIFE' ? 'life' : 'health_individu',
          ...(props.city ? { city: props.city} : ''),
          ...(props.policy_expired_date ? { policy_expired_date: props.policy_expired_date} : ''),
        };
        processUrl = `/plans?${qs.stringify(query)}`;
      } else if (gender) {
        processUrl = `/search?gender=${gender}&category=${category === 'LIFE' ? 'life' : 'health'}`;
      }
      break;
    case 'AUTO_CAR':
    case 'AUTO_CAR_EXT':
      const { vehicle_brand, vehicle_model, vehicle_type, vehicle_year, vehicle_area } = props;
      processUrl = '/search?category=auto';

      if (vehicle_brand && vehicle_model && vehicle_type && vehicle_year && vehicle_area) {
        const getAreaCode = areaCode.find(d => d.label === vehicle_area);

        let region;
        if (getAreaCode) {
          const splitedAreaCode = getAreaCode.value.split(' ');
          region = get(splitedAreaCode, '0', '');
        }

        const namePlan = `${vehicle_brand}_${vehicle_model}_${vehicle_type}_${region}`
        const query = { namePlan, year: vehicle_year, category: 'auto' };

        const specification = {
          region,
          area: vehicle_area,
          brand: vehicle_brand,
          model: vehicle_model,
          type: vehicle_type,
          year: vehicle_year,
        };
        localStorage.setItem('specification', JSON.stringify(specification));
        processUrl = `/plans?${qs.stringify(query)}`
      } else if (phone) {
        processUrl = `/search?category=auto&phoneNumber=${phone}`
      }

      break;
  }

  return processUrl;
};