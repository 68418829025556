export const createOrDeletePDF = async (
  constant,
  payload,
  method,
  isProposal,
  category,
) => {
  try {
    let response;
    await fetch(`/api/generator/pdf/`, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ payload, constant, isProposal, category })
    })
    .then(res => res.json())
    .then(res => response = res);
    return response;
  } catch(err) {
    console.log(err);
  }
};

