export const getTotalSumInsured = (age, budget) => {
  const denominator = [
    { lowerAge: 5, upperAge: 30, value: 33.333 },
    { lowerAge: 31, upperAge: 40, value: 23.81 },
    { lowerAge: 41, upperAge: 45, value: 16.667 },
    { lowerAge: 46, upperAge: 50, value: 12.821 },
    { lowerAge: 51, upperAge: 55, value: 9.804 },
    { lowerAge: 56, upperAge: 60, value: 8.333 },
  ];

  let totalSumInsured = 0;

  const matchDenominator = denominator.find(denom => denom.lowerAge <= age && denom.upperAge >= age);
  if (matchDenominator) {
    totalSumInsured = matchDenominator.value * budget;
  }

  return {
    value: totalSumInsured,
    lowerValue: totalSumInsured - (totalSumInsured * 30 / 100),
    upperValue: totalSumInsured + (totalSumInsured * 30 / 100),
  };
}