export const CAR_TAX_SERVICE_MAP = {
  'RENEWAL_1_YEAR' : {
    name: 'Perpanjang STNK Tahunan',
    url: 'stnk-tahunan',
  },
  'RENEWAL_5_YEAR' : {
    name: 'Perpanjang STNK 5 Tahunan',
    url: 'stnk-5-tahunan',
  },
  'VEHICLE_TITLE_TRANSFER' : {
    name: 'Balik Nama Kendaraan',
    url: 'balik-nama-kendaraan',
  },
  'BLOCKED_PLATE_NUMBER' : {
    name: 'Blokir Plat Nomor',
    url: 'blokir-plat-nomor',
  },
  'MUTATE_DOCUMENT' : {
    name: 'Mutasi Kendaraan',
    url: 'mutasi-kendaraan',
  },
};

const CAR_TAX_ORDER_STATUS_MAP = {
  'FULLY_PAID': {
    title: 'Sudah Dibayar',
    color: 'success',
  },
  'PAYMENT_PENDING': {
    title: 'Belum Dibayar',
    color: 'danger',
  },
  'PAYMENT_PROCESSING': {
    title: 'Menunggu Pembayaran',
    color: 'warning',
  },
};

const CAR_TAX_PROVIDER_STATUS_MAP = {
  'NEW': {
    title: 'Sudah Dibayar',
    color: 'success',
  },
  'PENDING_PICKUP': {
    title: 'Menunggu Pickup',
    color: 'warning',
  },
  'PENDING_SEND': {
    title: 'Menunggu Dikirim',
    color: 'warning',
  },
  'PENDING_RECIVE': {
    title: 'Sedang Dikirim',
    color: 'warning',
  },
  'PENDING_DISPATCH': {
    title: 'STNK/BPKP Diproses',
    color: 'warning',
  },
  'PENDING_DELIVERY': {
    title: 'STNK/BPKP Dikirim',
    color: 'warning',
  },
  'DELIVERED': {
    title: 'Selesai',
    color: 'default',
  },
  'REJECTED': {
    title: 'Dibatalkan',
    color: 'danger',
  },
};

export const getCarTaxStatusOrder = (statusOrder, providerStatusOrder) => {
  if (!statusOrder || !providerStatusOrder) {
    return CAR_TAX_ORDER_STATUS_MAP['PAYMENT_PENDING'];
  }

  if (providerStatusOrder === 'NEW') {
    return CAR_TAX_ORDER_STATUS_MAP[statusOrder];
  }

  return CAR_TAX_PROVIDER_STATUS_MAP[providerStatusOrder];
}
