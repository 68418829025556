import { localRest } from '../../client';

export async function addRecepient(data) {
  return await localRest(`/sendgrid/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    data,
  });
}

export async function addSendGridList(data) {
  return await localRest(`/sendgrid-list/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    data,
  });
}
