export const baseDokuFields = (doku) => {
  return [
    { name: 'AMOUNT', value: doku.amount },
    { name: 'BASKET', value: doku.basket },
    { name: 'CHAINMERCHANT', value: doku.chainMerchant },
    { name: 'CURRENCY', value: doku.currency },
    { name: 'EMAIL', value: doku.email },
    { name: 'MALLID', value: doku.mallId },
    { name: 'NAME', value: doku.name },
    { name: 'PURCHASEAMOUNT', value: doku.purchaseAmount },
    { name: 'PURCHASECURRENCY', value: doku.purchaseCurrency },
    { name: 'REQUESTDATETIME', value: doku.requestDateTime },
    { name: 'SESSIONID', value: doku.sessionId },
    { name: 'TRANSIDMERCHANT', value: doku.transIdMerchant },
    { name: 'WORDS', value: doku.words },
    { name: 'PAYMENTCHANNEL', value: doku.paymentChannel },
  ];
}

export const dokuInstallment = (doku) => {
  return [
    { name: 'PAYMENTTYPE', value: doku.paymentType },
    { name: 'INSTALLMENT_ACQUIRER', value: doku.installmentAcquirer },
    { name: 'TENOR', value: doku.tenor },
    { name: 'PROMOID', value: doku.promoId },
  ];
}

export const dokuKredivo = (doku) => {
  return [
    { name: 'SHIPPING_ADDRESS', value: doku.shippingAddress },
    { name: 'SHIPPING_CITY', value: doku.shippingCity },
    { name: 'SHIPPING_STATE',value: doku.shippingState },
    { name: 'SHIPPING_COUNTRY', value: doku.shippingCountry },
    { name: 'SHIPPING_ZIPCODE', value: doku.shippingZipcode },
    { name: 'MOBILEPHONE', value: doku.mobilePhone },
  ];
}

export const dokuRecurring = (doku) => {
  return [
    { name: 'ADDRESS', value: doku.shippingAddress },
    { name: 'CITY', value: doku.shippingCity },
    { name: 'STATE', value: doku.shippingState },
    { name: 'COUNTRY', value: doku.shippingCountry },
    { name: 'ZIPCODE', value: doku.shippingZipcode },
    { name: 'HOMEPHONE', value: doku.mobilePhone },
    { name: 'MOBILEPHONE', value: doku.mobilePhone },
    { name: 'CUSTOMERID', value: doku.customerId },
    { name: 'BILLNUMBER', value: doku.billNumber },
    { name: 'BILLDETAIL', value: doku.billDetail },
    { name: 'BILLTYPE', value: doku.billType },
    { name: 'STARTDATE', value: doku.startDate },
    { name: 'ENDDATE', value: doku.endDate },
    { name: 'EXECUTETYPE', value: doku.executeType },
    { name: 'EXECUTEDATE', value: doku.executeDate },
    { name: 'EXECUTEMONTH', value: doku.executeMonth },
    { name: 'FLATSTATUS', value: doku.flatStatus },
    { name: 'REGISTERAMOUNT', value: doku.registerAmount },
  ];
}