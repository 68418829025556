import { idr } from '../../shared/currency';

export function calculateResultObject(financialData) {
  const currentNetWorth = financialData.assets.total - financialData.debt.total;
  const monthlyCashFlow = financialData.income.total - (financialData.needs.primary.total + financialData.needs.secondary.total + financialData.installments.total);
  const emergencyFundAvailable = financialData.assets.attr.cash / (financialData.needs.primary.total + financialData.needs.secondary.total + financialData.installments.total);
  const allocationRation = financialData.needs.secondary.attr.insurance / financialData.income.total;
  const secondaryNeeds = financialData.needs.secondary.total / financialData.income.total;
  const currentAssetToNetWorth = financialData.assets.attr.cash / financialData.assets.total;
  const investmentAssetToNetWorth = financialData.assets.attr.investments / financialData.assets.total;
  const debtToAsset = financialData.debt.total / financialData.assets.total;
  const solvency = currentNetWorth / financialData.assets.total;
  const debtRepaymentAbility = financialData.installments.total / financialData.income.total;

  const resultObject = {
    currentNetWorth:{
      label: 'Kekayaan Bersih Saat Ini ',
      value: `${idr(currentNetWorth)}`,
      status: currentNetWorth >= 0 ? 'Sehat' : 'Tidak Sehat'
    },
    monthlyCashFlow: {
      label: 'Total Arus Kas Bersih Bulanan',
      value: `${idr(monthlyCashFlow)}`,
      status: monthlyCashFlow >= (0.1 * financialData.income.total) ? 'Sehat' : 'Tidak Sehat'
    },
    emergencyFundAvailable: {
      label: 'Total Ketersediaan Dana Darurat ',
      value: `${parseInt(emergencyFundAvailable)} Bulan`,
      status:emergencyFundAvailable >= 6 ? 'Sehat' : 'Tidak Sehat'
    },
    allocationRation: {
      label: 'Rasio Alokasi Untuk Resiko',
      value: `${(allocationRation * 100).toFixed(1)}%`,
      status: allocationRation >= 0.03 && allocationRation < 0.05 ? 'Sehat' : 'Tidak Sehat'
    },
    secondaryNeeds: {
      label: 'Rasio Kebutuhan Sekunder Terhadap Pemasukan',
      value: `${(secondaryNeeds * 100).toFixed(1)}%`,
      status: (secondaryNeeds * 100) <= 30 ? 'Sehat' : 'Tidak Sehat'
    },
    currentAssetToNetWorth: {
      label: 'Rasio Aset Lancar Terhadap Total Kekayaan Bersih',
      value: `${(currentAssetToNetWorth * 100).toFixed(1)}%`,
      status: (currentAssetToNetWorth*100) >= 15 && (currentAssetToNetWorth*100) <= 20 ? 'Sehat' : 'Tidak Sehat'
    },
    investmentAssetToNetWorth: {
      label: 'Rasio Aset Investasi Terhadap Kekayaan Bersih',
      value: `${(investmentAssetToNetWorth * 100).toFixed(1)}%`,
      status: (investmentAssetToNetWorth*100) >= 50 ? 'Sehat' : 'Tidak Sehat'
    },
    debtToAsset: {
      label: 'Rasio Utang Terhadap Aset',
      value: `${(debtToAsset * 100).toFixed(1)}%`,
      status: (debtToAsset*100) <= 50 ? 'Sehat' : 'Tidak Sehat'
    },
    solvency: {
      label: 'Rasio Solvabilitas',
      value: `${(solvency * 100).toFixed(1)}%`,
      status: (solvency*100) >= 35 ? 'Sehat' : 'Tidak Sehat'
    },
    debtRepaymentAbility: {
      label: 'Rasio Kemampuan Pelunasan Hutang',
      value: `${(debtRepaymentAbility * 100).toFixed(1)}%`,
      status: (debtRepaymentAbility*100) <= 35 ? 'Sehat' : 'Tidak Sehat'
    }
  }

  return resultObject;
}
