export const areaCode = [
  {
    'label': 'BL - Nanggroe Aceh Darussalam',
    'value': 'W1 1',
  },
  {
    'label': 'BB - Sumatera Utara',
    'value': 'W1 2',
  },
  {
    'label': 'BK - Sumatera Utara',
    'value': 'W1 3',
  },
  {
    'label': 'BA - Sumatera Barat',
    'value': 'W1 4',
  },
  {
    'label': 'BM - Riau',
    'value': 'W1 5',
  },
  {
    'label': 'BP - Kepulauan Riau',
    'value': 'W1 6',
  },
  {
    'label': 'BG - Sumatera Selatan',
    'value': 'W1 7',
  },
  {
    'label': 'BN - Bangka-Belitung',
    'value': 'W1 8',
  },
  {
    'label': 'BE - Lampung',
    'value': 'W1 9',
  },
  {
    'label': 'BD - Bengkulu',
    'value': 'W1 10',
  },
  {
    'label': 'BH - Jambi',
    'value': 'W1 11',
  },
  {
    'label': 'A - Banten',
    'value': 'W2 12'
  },
  {
    'label': 'B - DKI Jakarta',
    'value': 'W2 13',
  },
  {
    'label': 'D - Jawa Barat',
    'value': 'W2 14',
  },
  {
    'label': 'F - Jawa Barat',
    'value': 'W2 15',
  },
  {
    'label': 'T - Jawa Barat',
    'value': 'W2 16',
  },
  {
    'label': 'E - Jawa Barat',
    'value': 'W2 17',
  },
  {
    'label': 'Z - Jawa Barat',
    'value': 'W2 18',
  },
  {
    'label': 'H - Jawa Tengah',
    'value': 'W3 19',
  },
  {
    'label': 'G - Jawa Tengah',
    'value': 'W3 20',
  },
  {
    'label': 'K - Jawa Tengah',
    'value': 'W3 21',
  },
  {
    'label': 'R - Jawa Tengah',
    'value': 'W3 22',
  },
  {
    'label': 'AA - Jawa Tengah',
    'value': 'W3 23',
  },
  {
    'label': 'AD - Jawa Tengah',
    'value': 'W3 24',
  },
  {
    'label': 'AB - D.I. Yogyakarta (DIY)',
    'value': 'W3 25',
  },
  {
    'label': 'L - Jawa Timur',
    'value': 'W3 26',
  },
  {
    'label': 'W - Jawa Timur',
    'value': 'W3 27',
  },
  {
    'label': 'N - Jawa Timur',
    'value': 'W3 28',
  },
  {
    'label': 'P - Jawa Timur',
    'value': 'W3 29',
  },
  {
    'label': 'AG - Jawa Timur',
    'value': 'W3 30',
  },
  {
    'label': 'AE - Jawa Timur',
    'value': 'W3 31',
  },
  {
    'label': 'S - Jawa Timur',
    'value': 'W3 32',
  },
  {
    'label': 'M - Jawa Timur',
    'value': 'W3 33',
  },
  {
    'label': 'DK - Bali',
    'value': 'W3 34',
  },
  {
    'label': 'DR - Nusa Tenggara Barat (NTB)',
    'value': 'W3 35',
  },
  {
    'label': 'EA - Nusa Tenggara Barat (NTB)',
    'value': 'W3 36',
  },
  {
    'label': 'DH - Nusa Tenggara Timur (NTT)',
    'value': 'W3 37',
  },
  {
    'label': 'EB - Nusa Tenggara Timur (NTT)',
    'value': 'W3 38',
  },
  {
    'label': 'ED - Nusa Tenggara Timur (NTT)',
    'value': 'W3 39',
  },
  {
    'label': 'KB - Kalimantan Barat',
    'value': 'W3 40',
  },
  {
    'label': 'DA - Kalimantan Selatan',
    'value': 'W3 41',
  },
  {
    'label': 'KH - Kalimantan Tengah',
    'value': 'W3 42',
  },
  {
    'label': 'KT - Kalimantan Timur',
    'value': 'W3 43',
  },
  {
    'label': 'KU - Kalimantan Utara',
    'value': 'W3 44',
  },
  {
    'label': 'DB - Sulawesi Utara',
    'value': 'W3 45',
  },
  {
    'label': 'DL - Sulawesi Utara',
    'value': 'W3 46',
  },
  {
    'label': 'DM - Gorontalo',
    'value': 'W3 47',
  },
  {
    'label': 'DN - Sulawesi Tengah',
    'value': 'W3 48',
  },
  {
    'label': 'DD - Sulawesi Selatan',
    'value': 'W3 49',
  },
  {
    'label': 'DC - Sulawesi Barat',
    'value': 'W3 50',
  },
  {
    'label': 'DT - Sulawesi Tenggara',
    'value': 'W3 51',
  },
  {
    'label': 'DE - Maluku',
    'value': 'W3 52',
  },
  {
    'label': 'DG - Maluku Utara',
    'value': 'W3 53',
  },
  {
    'label': 'DS - Papua',
    'value': 'W3 54',
  },
  {
    'label': 'PA - Papua',
    'value': 'W3 55',
  },
  {
    'label': 'PB - Papua Barat',
    'value': 'W3 56',
  },
  {
    'label': 'DP - Sulawesi Selatan',
    'value': 'W3 57',
  },
];