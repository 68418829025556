import { DateUtil } from "./date";

/**
 * @function getIdentityFieldDisabled validation check to disable or enable field of identities (KTP, KK & Passport) on MemberForm
 * @param {props} Object consist of field (fieldname), birthDate, birthMonth, birthYear and citizenship in String type
 * @returns {Boolean} the value to be used on the respective field of disabled prop
 */

export const getIdentityFieldDisabled = (props) => {
  const {
    field,
    birthDate,
    birthMonth,
    birthYear,
    citizenship,
  } = props;

  let disabled = '';

  const dob = `${birthDate}/${birthMonth}/${birthYear}`;
  const age = DateUtil.getAge(dob);

  switch (field) {
    case 'identityCardNumber':
      switch (citizenship) {
        case 'WNI':
          if (age >= 17) {
            disabled = false;
            break;
          }

          disabled = true;
          break;
        case 'WNA':
          disabled = true;
          break;
        default:
          disabled = false;
          break;
      }
      break;
    case 'passportNumber':
      switch (citizenship) {
        case 'WNI':
          if (age > 17) {
            disabled = false;
            break;
          }

          disabled = true;
          break;
        case 'WNA':
          disabled = false;
          break;
        default:
          disabled = false;
          break;
      }
      break;
    case 'familyCardNumber':
      switch (citizenship) {
        case 'WNI':
          if (age > 17) {
            disabled = true;
            break;
          }

          disabled = false;
          break;
        case 'WNA':
          disabled = true;
          break;
        default:
          disabled = false;
          break;
      }
      break;
    default:
      disabled = false;
      break;
  }

  return disabled;
}

export const maritalStatusOptions = [
  {
    name: 'maritalStatus',
    value: 'SINGLE',
    label: 'Belum Menikah',
  },
  {
    name: 'maritalStatus',
    value: 'MARRIED',
    label: 'Menikah',
  },
  {
    name: 'maritalStatus',
    value: 'DIVORCED',
    label: 'Cerai Hidup',
  },
  {
    name: 'maritalStatus',
    value: 'WIDOW_WIDOWER',
    label: 'Cerai Mati',
  },
];

export const monthlyIncomeList = [
  { label: 'Di Bawah 5 Juta Rupiah', value: 'UNDER_5_MILLION_RUPIAH' },
  { label: '5 Juta - 20 Juta Rupiah', value: '5_MILLION_20_MILLION_RUPIAH' },
  { label: 'Di Atas 20 Juta Rupiah', value: 'ABOVE_20_MILLION_RUPIAH' },
];

export const sourceOfFundOptions = [
  {
    name: 'sourceOfFund',
    value: 'PEKERJAAN_TETAP',
    label: 'Pekerjaan Tetap',
  },
  {
    name: 'sourceOfFund',
    value: 'PEKERJAAN_LEPAS',
    label: 'Pekerjaan Lepas',
  },
  {
    name: 'sourceOfFund',
    value: 'WIRAUSAHA',
    label: 'Wirausaha',
  },
  {
    name: 'sourceOfFund',
    value: 'LAINNYA',
    label: 'Lainnya',
  },
];
