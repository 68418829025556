import { DateUtil } from "./date";

const { getCurrentDate, getDateTwoMonthsAgo } = DateUtil;

export const getQuotationType = (props, leadPhoneNumber) => {
  const {
    quotationStatus,
    statusOrder,
    quotationId,
    isReadyToIssue,
    policy,
    canDuplicate,
  } = props;

  let type = '';
  let displayName = 'N/A';
  let statusColor = '#C2C2C2';
  let path = '';
  let alias = '';
  let status = '';

  switch (quotationStatus) {
    case 'PENDING_QA':
      type = 'incomplete';
      displayName = 'Belum Lengkap';
      statusColor = '#A5C2F1';
      path = `/application/[id]/?phoneNumber=${leadPhoneNumber}`;
      alias = `/application/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
      status = 'interested';
      switch (statusOrder) {
        case 'PAYMENT_PENDING':
          status = 'paymentlink';
          if (!policy) {
            type = 'in-process';
            displayName = 'Diproses';
            statusColor = '#fAAF40';
            path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
            alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
          }
          break;
        case 'PARTIALLY_PAID':
        case 'FULLY_PAID':
          if (isReadyToIssue) {
            type = null;
            displayName = 'Selesai';
            statusColor = '#1EC494';
            path = '';
            alias = '';
          } else {
            type = 'awaiting-issuance';
            displayName = 'Belum Terbit';
            statusColor = '#A5C2F1';
            path = `/agents/quotations/[id]/?phoneNumber=${leadPhoneNumber}`;
            alias = `/agents/quotations/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
            if (policy && policy.status && policy.status === 'POLICY_ISSUED') {
              type = null;
              displayName = 'Selesai';
              statusColor = '#1EC494';
              path = '';
              alias = '';
            }
          }
          break;
        case 'PAYMENT_PROCESSING':
          status = 'salesunpaid';
          if (!policy) {
            type = 'in-process';
            displayName = 'Diproses';
            statusColor = '#FAAF40';
            path = `/application/[id]/?phoneNumber=${leadPhoneNumber}`;
            alias = `/application/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
          }
          break;
      }
      break;
    case 'SUSPEND':
      if (!isReadyToIssue) {
        switch (statusOrder) {
          case 'PAYMENT_PENDING':
          case 'PARTIALLY_PAID':
          case 'PAYMENT_PROCESSING':
          case 'FULLY_PAID':
            status = 'suspended';
            if (!policy) {
              type = 'suspended';
              displayName = 'Disuspend';
              statusColor = '#E81A1A';
              path = `/agents/quotations/[id]/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
              alias = `/agents/quotations/${quotationId}/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
            } else {
              if (policy.status && policy.status === 'PENDING') {
                type = 'suspended';
                displayName = 'Disuspend';
                statusColor = '#E81A1A';
                path = `/agents/quotations/[id]/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
                alias = `/agents/quotations/${quotationId}/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
              }
            }
            break;
        }
      }
      break;
    case 'REVISE':
      if (!isReadyToIssue) {
        switch (statusOrder) {
          case 'REFUND_COMPLETE':
          case 'REFUND_PROCESSING':
            if (!policy) {
              type = null;
              displayName = 'Dibatalkan';
              statusColor = '#C2C2C2';
              path = '';
              alias = '';
            } else {
              if (policy.status && policy.status === 'PENDING') {
                type = null;
                displayName = 'Dibatalkan';
                statusColor = '#C2C2C2';
                path = '';
                alias = '';
              }
            }
            break;
          case 'PAYMENT_PENDING':
            if (!policy) {
              type = null;
              displayName = 'Direvisi';
              statusColor = '#E81A1A';
              path = '';
              alias = '';
            }
            break;
          case 'PARTIALLY_PAID':
          case 'PAYMENT_PROCESSING':
            if (!policy) {
              type = null;
              displayName = 'Direvisi';
              statusColor = '#E81A1A';
              path = '';
              alias = '';
            } else {
              if (policy.status && policy.status === 'PENDING') {
                type = null;
                displayName = 'Direvisi';
                statusColor = '#E81A1A';
                path = '';
                alias = '';
              }
            }
            break;
          case 'FULLY_PAID':
            if (!policy) {
              type = null;
              displayName = 'Direvisi';
              statusColor = '#E81A1A';
              path = '';
              alias = '';
            } else {
              if (policy.status && ['PENDING', 'SUSPENDED', 'EXPIRED'].includes(policy.status)) {
                type = null;
                displayName = 'Direvisi';
                statusColor = '#E81A1A';
                path = '';
                alias = '';
              }
            }
            break;
        }
      }
      break;
    case 'CANCEL':
      switch (statusOrder) {
        case 'REFUND_COMPLETE':
        case 'REFUND_PROCESSING':
          type = null;
          displayName = 'Dibatalkan';
          statusColor = '#C2C2C2';
          path = '';
          alias = '';
          break;
        case 'PAYMENT_PENDING':
          if (!policy) {
            type = null;
            displayName = 'Dibatalkan';
            statusColor = '#C2C2C2';
            path = '';
            alias = '';
          }
          break;
        case 'PARTIALLY_PAID':
        case 'FULLY_PAID':
          if (!policy) {
            type = null;
            displayName = 'Dibatalkan';
            statusColor = '#C2C2C2';
            path = '';
            alias = '';
          } else {
            if (policy.status && ['PENDING', 'SUSPENDED', 'EXPIRED'].includes(policy.status)) {
              type = null;
              displayName = 'Dibatalkan';
              statusColor = '#C2C2C2';
              path = '';
              alias = '';
            }
          }
          break;
        case 'PAYMENT_PROCESSING':
          if (!policy) {
            type = null;
            displayName = 'Dibatalkan';
            statusColor = '#C2C2C2';
            path = '';
            alias = '';
          }
          break;
      }
      break;
    case 'ACTIVE':
      if (!statusOrder && !policy) {
        type = 'incomplete';
        displayName = 'Belum Lengkap';
        statusColor = '#A5C2F1';
        path = `/application/[id]/?phoneNumber=${leadPhoneNumber}`;
        alias = `/application/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
      }

      switch (statusOrder) {
        case 'PAYMENT_PENDING':
          if (!policy) {
            type = 'incomplete';
            displayName = 'Belum Lengkap';
            statusColor = '#A5C2F1';
            path = `/application/[id]/?phoneNumber=${leadPhoneNumber}`;
            alias = `/application/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
            status = 'paymentlink';
          } else {
            if (policy.status === 'PENDING') {
              type = 'in-process';
              displayName = 'Diproses';
              statusColor = '#fAAF40';
              path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
              alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
            }
          }
          break;
        case 'PARTIALLY_PAID':
          status = 'partiallypaid';
          if (isReadyToIssue) {
            if (policy && policy.status && policy.expireDate) {
              const moreThanTwoMonths = getDateTwoMonthsAgo(policy.expireDate) > getCurrentDate();
              const withinTwoMonths = getDateTwoMonthsAgo(policy.expireDate) <= getCurrentDate();
              const alreadyExpired = getCurrentDate() > new Date(policy.expireDate).getTime();

              switch (policy.status) {
                case 'POLICY_ISSUED':
                  if (moreThanTwoMonths || withinTwoMonths) {
                    type = null;
                    displayName = 'Selesai';
                    statusColor = '#1EC494';
                    path = '';
                    alias = '';
                  }
                  break;
                case 'SUSPENDED':
                  type = 'expired';
                  displayName = 'Pembayaran Jatuh Tempo';
                  statusColor = '#FFFFFF';
                  path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                  alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                  if (withinTwoMonths) {
                    type = 'expired';
                    displayName = 'Pembayaran Jatuh Tempo';
                    statusColor = '#FFFFFF';
                    path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                    alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                  }
                  break;
                case 'EXPIRED':
                  if (alreadyExpired) {
                    type = 'renewal';
                    displayName = 'Polis Akan Kadaluwarsa';
                    statusColor = '#FFFFFF';
                    path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                    alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                    if (canDuplicate !== null && !canDuplicate) {
                      path = '';
                      alias = '';
                    }
                  }
                  break;
              }
            }
          }
          break;
        case 'PAYMENT_PROCESSING':
          if (!policy) {
            type = 'incomplete';
            displayName = 'Belum Lengkap';
            statusColor = '#A5C2F1';
            path = `/application/[id]/?phoneNumber=${leadPhoneNumber}`;
            alias = `/application/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
            status = 'paymentlink';
          }
          break;
        case 'FULLY_PAID':
          status = 'renewal';
          if (policy && policy.status) {
            const moreThanTwoMonths = getDateTwoMonthsAgo(policy.expireDate) > getCurrentDate();
            const withinTwoMonths = getDateTwoMonthsAgo(policy.expireDate) <= getCurrentDate();
            const alreadyExpired = getCurrentDate() > new Date(policy.expireDate).getTime()

            switch (policy.status) {
              case 'REQUESTED':
                type = null;
                displayName = 'Selesai';
                statusColor = '#1EC494';
                path = '';
                alias = '';
                break;
              case 'PENDING':
                type = null;
                displayName = 'Selesai';
                statusColor = '#1EC494';
                path = '';
                alias = '';
                if (moreThanTwoMonths) {
                  type = null;
                  displayName = 'Selesai';
                  statusColor = '#1EC494';
                  path = '';
                  alias = '';
                  if (!isReadyToIssue) {
                    type = 'suspended';
                    displayName = 'Disuspend';
                    statusColor = '#E81A1A';
                    path = `/agents/quotations/[id]/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
                    alias = `/agents/quotations/${quotationId}/?source=tso-suspend&phoneNumber=${leadPhoneNumber}`;
                  }
                }
                break;
              case 'POLICY_ISSUED':
                if (moreThanTwoMonths) {
                  type = null;
                  displayName = 'Selesai';
                  statusColor = '#1EC494';
                  path = '';
                  alias = '';
                } else if (withinTwoMonths) {
                  if (isReadyToIssue) {
                    type = 'renewal';
                    displayName = 'Polis Akan Kadaluwarsa';
                    statusColor = '#FFFFFF';
                    path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                    alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                    if (canDuplicate !== null && !canDuplicate) {
                      path = '';
                      alias = '';
                    }
                  }
                }
                break;
              case 'SUSPENDED':
                type = 'expired';
                displayName = 'Pembayaran Jatuh Tempo';
                statusColor = '#FFFFFF';
                path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                if (moreThanTwoMonths) {
                  type = null;
                  displayName = 'Selesai';
                  statusColor = '#1EC494';
                  path = '';
                  alias = '';
                }
                break;
              case 'EXPIRED':
                if (isReadyToIssue && (withinTwoMonths || alreadyExpired)) {
                  type = 'renewal';
                  displayName = 'Polis Akan Kadaluwarsa';
                  statusColor = '#FFFFFF';
                  path = `/checkout/[id]/?phoneNumber=${leadPhoneNumber}`;
                  alias = `/checkout/${quotationId}/?phoneNumber=${leadPhoneNumber}`;
                  if (canDuplicate !== null && !canDuplicate) {
                    path = '';
                    alias = '';
                  }
                }
                break;
            }
          }
          break;
      }
      break;
  }

  return { type, displayName, path, alias, statusColor, status };
};

export const QUOTATION_STATUS_MAP = {
  'ACTIVE': {
    title: 'Aktif',
    color: '#1EC494',
  },
  'PENDING_QA': {
    title: 'Belum QA',
    color: '#a5c2f1',
  },
  'PENDING': {
    title: 'Belum Terbit',
    color: '#a5c2f1',
  },
  'REVISE': {
    title: 'Direvisi',
    color: '#e81a1a',
  },
  'SUSPEND': {
    title: 'Disuspend',
    color: '#EA4B4B',
  },
  'CANCEL': {
    title: 'Dibatalkan',
    color: '#c2c2c2',
  },
};

export const ORDER_STATUS_MAP = {
  'FULLY_PAID': {
    title: 'Sudah Dibayar',
    color: '#1EC494',
  },
  'PARTIALLY_PAID': {
    title: 'Dibayar',
    color: '#1EC494',
  },
  'PAYMENT_PROCESSING': {
    title: 'Belum Dibayar',
    color: '#F39C12'
  },
  'PAYMENT_PENDING': {
    title: 'Belum Dibayar',
    color: '#1D66DD',
  },
  'REFUND_COMPLETE': {
    title: 'Refund Complete',
    color: '#B6B6B6',
  },
  'REFUND_COMPLETE_VIA_PG': {
    title: 'Refund Sukses',
    color: '#B6B6B6',
  },
  'REFUND_PROCESSING': {
    title: 'Refund Processing',
    color: '#EA4B4b',
  },
  'REFUND_PROCESSING_VIA_PG': {
    title: 'Refund Diproses',
    color: '#EA4B4B',
  },
};

export default { getQuotationType };
