import { get, set } from "js-cookie";
import { isEmpty } from "clientUtils";

/** CONSTANT */
import { AGENT_CONSTANTS } from '../modules/agents/constants/agent.constants';
const leadFormKey = 'LP_LEADFORM';

/**
* universal function to set cookie
* if not exist previously
* store on cookie in the browser for n days
* @param {String} key - key name to store on cookie
* @param {String} value - the value that will stored on cookie with it's key name
* @param {Number} expires - Define when the cookie will be removed
*/
export const setCookie = (key, value, expires) => {
  if (!get(key)) {
    set(key, value, { expires });
  } 
};

/**
* @function setLeadsToCookie set leads data into cookie if there is no LP_LEADFORM key previously
* @param {Object} data - leads data to store on cookie
*/
export const setLeadsToCookie = (data) => {
  const token = get('token');
  const organization = AGENT_CONSTANTS.getAgentByToken(token);

  if (organization.isInternalAgent && organization.name === 'Lifepal') {
    return;
  }

  const leadFormKey = 'LP_LEADFORM';
  const currentLeadsOnCookie = get(leadFormKey) && JSON.parse(get(leadFormKey));
  let leadsData = {};

  if (currentLeadsOnCookie && !isEmpty(currentLeadsOnCookie)) {
    leadsData = currentLeadsOnCookie;
    leadsData.phoneNumber = data.phone ? data.phone : currentLeadsOnCookie.phone;
    leadsData.gender = data.gender ? data.gender : currentLeadsOnCookie.gender;
    leadsData.age = data.age ? data.age : currentLeadsOnCookie.age;
    leadsData.fullName = data.fullName ? data.fullName : currentLeadsOnCookie.fullName;
  } else {
    leadsData.phoneNumber = data.phone;
    leadsData.gender = data.gender;
    leadsData.age = data.age;
    leadsData.fullName = data.fullName;
  }

  set(leadFormKey, JSON.stringify(leadsData), 30);
};

/**
* @function getLeadsFromCookie - retrieve cookie with key LP_LEADFORM to prefill leads data
* @return {Object} - returns object of leads data if any & empty object if none
*/
export const getLeadsFromCookie = () => {
  const token = get('token');
  const organization = AGENT_CONSTANTS.getAgentByToken(token);

  if (organization.isInternalAgent && organization.name === 'Lifepal') {
    return {};
  }

  try {
    let isKeyExist = get(leadFormKey);
    if (isKeyExist) return JSON.parse(get(leadFormKey))
    return {};
  } catch (error) {
    console.error(error);
    return {};
  }
};
