export const getPremiumBysupportedPaymentTerm = (plan, isPlanListing) => {
  if (!Object.keys(plan).length || !plan.product) {
    return {
      amount: 0,
      paymentTerm: '-',
    };
  }

  const { product, total_premium } = plan;
  const totalPremiumOneTime = total_premium * (product.term / 12);

  switch (true) {
    case product.support_monthly:
      return {
        amount: totalPremiumOneTime / product.term,
        paymentTerm: getPaymentTermLabel(product.term, 'MONTHLY', isPlanListing),
      };
    case product.support_quarterly:
      return {
        amount: totalPremiumOneTime / (product.term / 3),
        paymentTerm: getPaymentTermLabel(product.term, 'QUARTERLY', isPlanListing),
      };
    case product.support_semesterly:
      return {
        amount: totalPremiumOneTime / (product.term / 6),
        paymentTerm: getPaymentTermLabel(product.term, 'SEMESTERLY', isPlanListing),
      };
    case product.support_yearly:
      return {
        amount: totalPremiumOneTime / (product.term / 12),
        paymentTerm: getPaymentTermLabel(product.term, 'YEARLY', isPlanListing),
      };
    default:
      return {
        amount: product.term < 12 ? total_premium : totalPremiumOneTime,
        paymentTerm: getPaymentTermLabel(product.term, 'ONE_TIME', isPlanListing),
      };
  }
};

export const getYearAndMonth = (term) => {
  const years = Math.floor(term / 12);
  const months = term % 12;

  let result = `${years} Tahun ${months} Bulan`;

  if (!years) {
    result = `${months} Bulan`;
  } else if (!(term % 12)) {
    result = `${years} Tahun`;
  }

  return result;
};

export const getPaymentTermLabel = (recurringCount, paymentTerm, isPlanListing) => {
  if (!recurringCount && !isPlanListing) {
    return 'Tahun';
  }

  const labelByTerm = {
    'MONTHLY': () => `Bulan selama ${getYearAndMonth(recurringCount)}`,
    'QUARTERLY': () => `3 Bulan selama ${getYearAndMonth(recurringCount)}`,
    'SEMESTERLY': () => `6 bulan selama ${getYearAndMonth(recurringCount)}`,
    'YEARLY': () => `Tahun selama ${getYearAndMonth(recurringCount)}`,
    'ONE_TIME': () => getYearAndMonth(recurringCount) === '1 Tahun' ? 'Tahun' : getYearAndMonth(recurringCount),
  };

  const labelByTermWithDue = {
    'MONTHLY': () => ({
      term: 'Bulan',
      due: `* selama ${getYearAndMonth(recurringCount)}`,
      termWithDue: `Bulan selama ${getYearAndMonth(recurringCount)}`,
    }),
    'QUARTERLY': () => ({
      term: '3 Bulan',
      due: `* selama ${getYearAndMonth(recurringCount)}`,
      termWithDue: `3 Bulan selama ${getYearAndMonth(recurringCount)}`,
    }),
    'SEMESTERLY': () => ({
      term: '6 Bulan',
      due: `* selama ${getYearAndMonth(recurringCount)}`,
      termWithDue: `6 bulan selama ${getYearAndMonth(recurringCount)}`,
    }),
    'YEARLY': () => ({
      term: 'Tahun',
      due: `* Pembayaran Pertahun * selama ${getYearAndMonth(recurringCount)}`,
      termWithDue: `Tahun selama ${getYearAndMonth(recurringCount)}`,
    }),
    'ONE_TIME': () => ({
      term: getYearAndMonth(recurringCount) === '1 Tahun' || !recurringCount
        ? 'Tahun' : getYearAndMonth(recurringCount),
      termWithDue: getYearAndMonth(recurringCount) === '1 Tahun' || !recurringCount
        ? 'Tahun' : getYearAndMonth(recurringCount),
    }),
  };

  if (isPlanListing) {
    if (!recurringCount && !paymentTerm) {
      return labelByTermWithDue['YEARLY']();
    }
    return labelByTermWithDue[paymentTerm]();
  }

  return labelByTerm[paymentTerm]();
}
