import mixpanel from 'mixpanel-browser';
import Cookie from 'js-cookie';

import { AGENT_CONSTANTS } from '../modules/agents/constants/agent.constants';

export const userActivity = {
  constants: {
    newLeadCreation: 'New Lead Created',
    newApplicationCreation: 'New Application Creation',
    buyPlan: 'Clicked Beli - Plan Listing Page',
    buyCompare: 'Clicked Beli - Bandikan Produk Page',
    checkout: 'Clicked \'Check out\'',
    saveApplication: 'Clicked \'Simpan & Lanjutkan Nanti\' - Aplikasi Page',
    saveCheckout: 'Clicked \'Simpan & Lanjutkan Nanti\' - Checkout Page',
    proceedCheckout: 'Clicked \'Proses Pembarayan\'',
    proceedApplication: 'Clicked \'Proses Aplikasi\'',
    continueApplication: 'Clicked \'Lanjutkan Proses\'',
    sendWA: 'Clicked \'Kirim Pesan\'',
    editPlan: 'Clicked Edit',
    detailPlan: 'Clicked Lihat Detail',
    comparePlan: 'User clicks \'Bandikan Produk\' in Plan listing page',
    cancelApplication: 'Clicked \'Batalkan Aplikasi\' - Aplikasi Page',
    cancelCheckout: 'Clicked \'Batalkan Aplikasi\' - Checkout Page',
    featureSelection: 'Feature selected in PSF',
    familySelection: 'Family member selected in PSF',
    citySelection: 'City selected in PSF',
    budgetSelection: 'Budget selected in PSF',
    comparePdfDownload: 'Clicked Download - Bandikan Produk Page',
    compareCopyLink: 'Clicked Copy Link- Bandikan Produk Page',
    compareWaClick: 'Clicked WA - Bandikan Produk Page',
    filterInsuranceCompany: 'Clicked Filter - Perusahaan Asuransi',
    compareFindLocation: 'Clicked \'Cari Lokasi Anda\' - Bandingkan Produk Page',
    saveDiscount: 'Clicked \'Simpan\' - Atur Diskon Popup',
    usePromo: 'Clicked \'Gunakan\' - Use Promo',
    chatCustomerApplication: 'Clicked \'Chat Pelanggan\' - Aplikasi Card',
    callCustomerApplication: 'Clicked \'Memanggil Pelanggan\' - Aplikasi Card',
    deleteCustomerApplication: 'Clicked \'Hapus Pelanggan\' - Aplikasi Card',
    moveCustomerApplication: 'Clicked \'Pindahkan Pelanggan\' - Aplikasi Card',
    arrangeScheduleApplication: 'Clicked \'Atur Jadwal\' - Aplikasi Card',
    filterCustomer: 'Clicked \'Urutkan\' - Pelanggan Page',
    callHistoryApplication: 'Clicked \'Riwayat Panggilan\' - Aplikasi Card',
    notesApplication: 'Clicked \'Catatan\' - Aplikasi Card',
    newLeadPopup: 'User Receive New Lead from Popup',
    agentNameKey: '$name',
    agentEmailKey: '$email',
  },
  init: async (token) => {
    const envKey = await AGENT_CONSTANTS.getEnvConfig('MIXPANEL_TOKEN')
    const organization = AGENT_CONSTANTS.getAgentByToken(token);

    if (
      envKey &&
      envKey.value &&
      organization.isInternalAgent &&
      organization.name === 'Lifepal'
    ) {
      mixpanel.init(envKey.value);
    }
  },
  track: (eventName, customerData) => {
    const token = Cookie.get('token');
    const organization = AGENT_CONSTANTS.getAgentByToken(token);

    if (organization.isInternalAgent && organization.name === 'Lifepal') { // only track internal agent (TSO)
      try {
        mixpanel.track(eventName, {
          ...customerData,
          [userActivity.constants.agentNameKey]: organization.agentName,
          [userActivity.constants.agentEmailKey]: organization.agentEmail,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
