export const categoryLib = {
  'micro-rawat-inap': 'Mikro',
  'life': 'Jiwa',
  'personal-accident': 'Kecelakaan Diri',
  'syariah': 'Syariah',
  'travel': 'Travel',
  'health': 'Kesehatan',
  'auto': 'Mobil',
  'moto': 'Motor',
}

export function getAutoCategory(category) {
  return category === 'auto' ? 'car' : 'moto';
}

export const relationOptions = [
  { value: 'Saya Sendiri', label: 'Saya Sendiri' },
  { value: 'Pasangan', label: 'Pasangan' },
  { value: 'Putra', label: 'Putra' },
  { value: 'Putri', label: 'Putri' },
  { value: 'Ayah', label: 'Ayah' },
  { value: 'Ibu', label: 'Ibu' },
  { value: 'Kakek', label: 'Kakek' },
  { value: 'Nenek', label: 'Nenek' },
  { value: 'Ayah Ipar', label: 'Ayah Ipar' },
  { value: 'Ibu Ipar', label: 'Ibu Ipar' },
];

export const beneficiaryOptions = [
  { label: 'Pasangan Resmi', value: 'Pasangan Resmi' },
  { label: 'Anak Kandung', value: 'Anak Kandung' },
  { label: 'Orang Tua Kandung', value: 'Orang Tua Kandung' },
  { label: 'Kakak Kandung', value: 'Kakak Kandung' },
  { label: 'Adik Kandung', value: 'Adik Kandung' },
];
