export const formatPhoneNumber = (phone) => {
  const p1 = phone.substring(0, 3);
  const p2 = phone.substring(3, 6);
  const p3 = phone.substring(6, 10);
  const p4 = phone.substring(10, 14);
  const p5 = phone.substring(14);

  let number = '';
  if (p1) {
    number += p1;
  }
  if (p2) {
    number += ` ${p2}`;
  }
  if (p3) {
    number += ` ${p3}`;
  }
  if (p4) {
    number += ` ${p4}`;
  }
  if (p5) {
    number += ` ${p5}`;
  }
  return number;
};

export const idnPhoneNumber = (num) => {
  let phone = num;
  let format = num.replace(/[^\d.-]/g, '');
  if (format === '' || format === '62') {
    return '';
  } else if (phone.length <= 19) {
    if (phone.substring(0, 2) !== '+62') {
      if (num.substring(0, 3) === '+62') {
        format = format.slice(2);
      }
      if (num.substring(0, 1) === '0') {
        format = format.slice(1);
      }
      phone = `+62${format}`;
    } else {
      phone = `+62${num.slice(5).replace(/[^\d.-]/g, '')}`;
    }
    return formatPhoneNumber(phone);
  }
};

export const handlePhoneNumber = (phone) => {
  return {
    idn: idnPhoneNumber(phone),
    format: phone,
    number: phone.split(' ').join('').replace('+62', '0'),
    value: phone.split(' ').join(''),
  };
};

export const idnPhoneValidation = (phone) => {
  const phoneNumber = phone.replace(/\s/g, '');
  const validNumber = /^(^\8)(\d{3,4}-?){2}\d{3,4}$/;

  if (!phoneNumber.match(validNumber)) {
    return false;
  }

  return true;
};
