import get from 'lodash/get';
import QRCode from 'qrcode';
import { toast } from 'react-toastify';

import { baseDokuFields, dokuInstallment, dokuKredivo, dokuRecurring } from '../../lib/Doku';

const getEnvConfig = async (envKey) => {
  let response;

  try {
    const responseStream = await fetch(`/api/get-env-config?key=${envKey}`);
    response = await responseStream.json();
  } catch (err) {
    console.error(err);
  }

  return response;
}

export const dokuHandler = async ({ values, paymentMethod }) => {
  const HiddenForm = document.createElement('form');

  const dokuUrl = await getEnvConfig('DOKU_URL');

  HiddenForm.action = dokuUrl && dokuUrl.value;
  HiddenForm.id = 'order-form';
  HiddenForm.method = 'POST';
  HiddenForm.style.display = 'none';

  let fields = baseDokuFields(values);

  if (paymentMethod === 'INSTALLMENT') {
    fields = fields.concat(dokuInstallment(values));
  }

  if (paymentMethod === 'KREDIVO') {
    fields = fields.concat(dokuKredivo(values));
  }

  if (paymentMethod.includes('RECURRING')) {
    fields = fields.concat(dokuRecurring(values));
  }

  fields.forEach(field => {
    const el = document.createElement('input');
    el.type = 'hidden';
    el.value = String(field.value);
    el.name = String(field.name);
    HiddenForm.appendChild(el);
  });

  document.body.appendChild(HiddenForm);
  HiddenForm.submit();
}

export const xenditHandler = async ({
  values,
  paymentMethod,
  isMobileMode,
  openOvoModal,
  openOTPModal,
  finishPayment,
  setDirectDebitId,
  setPaymentInfoItem,
  openQRModal,
}) => {

  if (paymentMethod === 'OVO') {
    openOvoModal();
    return;
  }

  if (paymentMethod === 'AUTODEBIT_BRI') {
    if (values && values.autodebit) {
      if (values.autodebit.last_created_invoice_url) {
        window.open(
          values.autodebit.last_created_invoice_url,
          '_blank',
          'noopener,noreferrer,nofollow'
        );

        return;
      }

      if (values.autodebit.is_otp_required) {
        setDirectDebitId(values.autodebit.id);
        openOTPModal();
        return;
      }

      finishPayment();
    }
  }

  if (['LINKAJA', 'DANA'].includes(paymentMethod)) {
    if (
      values.actions &&
      values.actions.desktop_web_checkout_url &&
      values.actions.mobile_web_checkout_url
    ) {
      let checkoutUrl = values.actions.desktop_web_checkout_url;
      if (isMobileMode) checkoutUrl = values.actions.mobile_web_checkout_url;

      window.location = checkoutUrl;
    }
  } else if (paymentMethod === 'QRIS') {
    try {
      const qrCode = await QRCode.toDataURL(values.qr_string);
      setPaymentInfoItem({ qrCode, amount: values.amount });
      openQRModal();
    } catch (err) {
      toast.error('Failed to generate QR Code.');
    }
  }
}

export const midtransHandler = ({
  values,
  paymentMethod,
  isMobileMode,
  setPaymentInfoItem,
  openModal,
}) => {
  if (get(values, 'midtransCharge')) {
    const midtransPaymentMethodAction = {
      'GOPAY': () => {
        if (isMobileMode) {
          const deeplinkObj = values.midtransCharge.actions.find(row => row.name === 'deeplink-redirect');
          if (deeplinkObj) {
            window.location = deeplinkObj.url;
          }
          return;
        }

        const qrCodeObj = values.midtransCharge.actions.find(row => row.name === 'generate-qr-code');
        if (qrCodeObj) {
          setPaymentInfoItem({
            amount: values.midtransCharge.gross_amount,
            qrCode: qrCodeObj.url
          });
          openModal();
        }
      },
      'INDOMARET': () => {
        const paymentCode = values.midtransCharge.payment_code;
        if (paymentCode) {
          setPaymentInfoItem({
            amount: values.midtransCharge.gross_amount,
            paymentCode,
          });
          openModal();
        }
      },
      'AKULAKU': () => {
        const redirectUrl = values.midtransCharge.redirect_url;
        if (redirectUrl) {
          window.location = redirectUrl;
        }
      },
      'BCA_KLIKPAY': () => midtransPaymentMethodAction['AKULAKU'](),
    };

    midtransPaymentMethodAction[paymentMethod]();
    return;
  }

  //  handle OTHER -MIDTRANS payment method
  if (get(values, 'token')) {
    window.snap.pay(values.token);
  }
}

export const indodanaHandler = ({ values }) => {
  if (get(values, 'redirectUrl')) {
    window.location = values.redirectUrl;
  }
}

export const danaHandler = ({ values, orderId }) => {
  if (get(values, 'oauthUrl')) {
    localStorage.setItem('orderId', orderId);
    window.location = values.oauthUrl;
  }
}