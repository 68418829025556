export const pricingText = (props) => {
  const { isAutoMoto, item, premiumData, parseToIdr, discount } = props

  if (!isAutoMoto) {
    return parseToIdr(
      item.before_price < premiumData.amount && item.before_price > 0
        ? item.before_price
        : premiumData.amount,
    );
  }

  if (discount) {
    const finalDiscountPercentage = 1 - (parseInt(discount) / 100);

    return parseToIdr(
      item.before_price < premiumData.amount && item.before_price > 0
        ? item.before_price * finalDiscountPercentage
        : premiumData.amount * finalDiscountPercentage,
    );
  }

  return parseToIdr(
    item.before_price < premiumData.amount && item.before_price > 0
      ? item.before_price * 0.75
      : premiumData.amount * 0.75,
  );
};

export const getDiscountPercentage = (brandsConfig, brandId) => {
  if (!brandsConfig || Object.keys(brandsConfig).length <= 0) {
    return null;
  }
  
  const discountList = brandsConfig.discount;
  
  return discountList.find((discount) => discount.brands.includes(brandId));
}

export const getVoucherPercentage = (brandsConfig, brandId) => {
  if (!brandsConfig || Object.keys(brandsConfig).length <= 0) {
    return null;
  }

  const voucherList = (brandsConfig).voucher;

  return voucherList.find((voucher) => voucher.brands.includes(brandId));
}