import decode from 'jsonwebtoken/decode';

export const AGENT_CONSTANTS = {
	levels: {
		'Junior': {
			name: 'junior',
			icon: '/static/images/agents/levels/junior.svg'
		},
		'Senior': {
			name: 'senior',
			icon: '/static/images/agents/levels/senior.svg'
		},
		'Bronze': {
			name: 'bronze',
			icon: '/static/images/agents/levels/bronze.svg'
		},
		'Silver': {
			name: 'silver',
			icon: '/static/images/agents/levels/silver.svg'
		},
		'Gold': {
			name: 'gold',
			icon: '/static/images/agents/levels/gold.svg'
		},
		'Platinum': {
			name: 'platinum',
			icon: '/static/images/agents/levels/platinum.svg'
		}
	},
	insuranceCategory: [
		{ label: 'Asuransi Kesehatan', value: 'health' },
		{ label: 'Asuransi Jiwa', value: 'life' },
		{ label: 'Asuransi Mobil', value: 'auto' },
		{ label: 'Asuransi Motor', value: 'moto' },
		{ label: 'Asuransi Perjalanaan', value: 'travel' },
		{ label: 'Asuransi Penyakit Kritis & Infeksi', value: 'micro-rawat-inap' },
		{ label: 'Asuransi Syariah', value: 'syariah' },
		{ label: 'Asuransi Properti', value: 'property' },
		{ label: 'Asuransi Sepeda', value: 'bike' },
		{ label: 'Asuransi Gadget', value: 'gadget' },
		{ label: 'Asuransi Binatang Peliharaan', value: 'pet' },
		{ label: 'Asuransi Kecelakaan Diri', value: 'personal-accident' },
	],
	getAgentByToken: (token) => {
		const userInfo = decode(token);
		let organization = {};
		if (userInfo && Object.keys(userInfo).length) {
			organization.isInternalAgent = userInfo.groups.includes('internal-agents');
			organization.isTSOSupervisor = userInfo.groups.includes('tso-supervisor');
			
			organization.userId = userInfo.user_id;
			organization.agentName = userInfo.full_name;
			organization.agentEmail = userInfo.email;
			
			if (userInfo.organization) {
				organization.name = userInfo.organization.name;
				organization.leadsOwner = userInfo.organization.leads_owner;
				organization.showCommission = userInfo.organization.show_commission;
				organization.enableQa = userInfo.organization.enable_qa;
				organization.showLevelInDashboard = userInfo.organization.show_level_in_dashboard;
				organization.showShortcutsInDashboard = userInfo.organization.show_shortcuts_in_dashboard;
				organization.useSimpleLeadStatus = userInfo.organization.use_simple_lead_status;
				organization.showMemberStructure = userInfo.organization.show_member_structure;
				organization.isExternalAgent = userInfo.groups.includes('internal-agents') && userInfo.organization.name !== 'Lifepal';
			}
		}
		return organization;
	},
	getProductsByRole: (token, category) => {
		let productCategories = [...AGENT_CONSTANTS.insuranceCategory]; //clone
		let agentRole = AGENT_CONSTANTS.getAgentByToken(token);

		if (
			!agentRole.isExternalAgent &&
			category &&
			AGENT_CONSTANTS.agentProductsMap[category] &&
			AGENT_CONSTANTS.agentProductsMap[category].length
		) {
			productCategories = AGENT_CONSTANTS.agentProductsMap[category].map(agentProduct =>
				AGENT_CONSTANTS.insuranceCategory.filter(insuranceProduct => agentProduct === insuranceProduct.label)
			).flat();
		}
		return productCategories;
	},
	popularKotas: [
		{ id: 1, label: 'Jakarta', value: 'Jakarta' },
		{ id: 2, label: 'Bandung', value: 'Bandung' },
		{ id: 3, label: 'Bogor', value: 'Bogor' },
		{ id: 4, label: 'Yogyakarta', value: 'Yogyakarta' },
		{ id: 5, label: 'Surabaya', value: 'Surabaya' },
		{ id: 6, label: 'Medan', value: 'Medan' },
		{ id: 7, label: 'Semarang', value: 'Semarang' },
		{ id: 8, label: 'Cirebon', value: 'Cirebon' },
		{ id: 9, label: 'Makassar', value: 'Makassar' },
	],
	agentProductsMap: {
		'tso-health': ['Asuransi Kesehatan', 'Asuransi Syariah'],
		'tso-life': ['Asuransi Jiwa'],
		'tso-auto': ['Asuransi Mobil', 'Asuransi Motor'],
		'high-value-health': ['Asuransi Kesehatan', 'Asuransi Syariah', 'Asuransi Jiwa'],
	},
	updateCustomerAvailability: (payload, callback) => {
		try {
			const response = fetch(`/api/leads/list/`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});
			callback && callback(response);
		} catch (err) {
			callback({ error: true, err });
		}
	},
	getCallingSystemConfig: async (token, callback) => {
		try {
			const responseStream = await fetch(`/api/agents/calling-system-info/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ token })
			});
			const response = await responseStream.json();
			callback && callback(response);
		} catch (err) {
			console.error(err);
		}
	},
	getEnvConfig: async (envKey) => {
		let response;

		try {
			const responseStream = await fetch(`/api/get-env-config/?key=${envKey}`);
			response = await responseStream.json();
		} catch (err) {
			console.error(err);
		}

		return response;
	},
	joinCall: async (payload, token) => {
		let response;

		try {
			await fetch(`/api/agents/calls/join-call/`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify(payload)
			});
			response = {};
		} catch (err) {
			console.log(err);
		}
		return response;
	},
	getTemplateParams: async (templateName, token) => {
		let response;

		try {
			const responseStream = await fetch(`/api/leads/message/${templateName}/`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});
			response = await responseStream.json();
		} catch (err) {
			console.error(err);
			response = { hasError: true };
		}

		return response;
	},
	canAcceptNotification: (eventTimeStamp, acceptedDelay) => {
    const eventHour = new Date(eventTimeStamp).getHours();
    const eventMinute = new Date(eventTimeStamp).getMinutes();
    const eventSecond = new Date(eventTimeStamp).getSeconds();

    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const currentSecond = new Date().getSeconds();

		// accept the future and acceptedDelay
    if (
      ((eventHour === currentHour) || (eventHour > currentHour))
      && ((eventMinute === currentMinute) || (eventMinute > currentMinute))
      && ((eventSecond - currentSecond) <= acceptedDelay)
    ) {
      return true;
    }
    return false;
	}
};

export default { AGENT_CONSTANTS };
