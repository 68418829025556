/**
 * Convert a space separated string to an upper snake cased string.
 * @param  {String} string String to upperSnakeCase
 * @returns {String}        upperSnakeCase'd string
 */
export function upperSnakeCase (string) {
  return string.split(' ').join('_').toUpperCase();
}

/**
 * Convert an underscore or space sepertated sting to a camel-cased string.
 * @param  {String} string String to camelCase
 * @returns {String}        camelCase'd string
 */
export function camelCase (string) {
  let words = string.toLowerCase().split(/_| /);

  return words.reduce((word, next) => {
    return word + next[0].toUpperCase() + next.slice(1);
  });
}

/**
 * Validates the email against the regex
 */
export function isEmail(email) {
  // eslint-disable-next-line
  const re = /^[^@]+@[^.]+\..+$/;

  return re.test(email);
}

/**
 * Capitalises the first character of the string
 */
export function capitalize (string) {
  if (string) {
    return string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase();
  }

  return string;
}
